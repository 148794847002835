<template>
  <v-card class="hotel-card my-5">
    <v-card-title>
      {{ hotel.name }}
    </v-card-title>
    <v-card-text>
      <v-list v-if="hotel.address">
        <v-list-item>{{ hotel.address.line1 }}</v-list-item>
        <v-list-item v-if="hotel.address.line2">{{ hotel.address.line2 }}</v-list-item>
        <v-list-item>
          {{ `${hotel.address.city}, ${hotel.address.state.value} ${hotel.address.zip}` }}
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'HotelCard',
  props: {
    hotel: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
.hotel-card {
  .v-card {
    &__actions {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
  .v-list-item {
    min-height: auto;
  }
}
</style>
